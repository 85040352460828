import React from 'react'
import svgToMiniDataURI from 'mini-svg-data-uri'

const SVGImage = ({ svgContent, className, alt, ...rest }) => {
  if (!svgContent) {
    return null
  }

  return (
    <picture title={alt} className={className} {...rest}>
      <source srcSet={`${svgToMiniDataURI.toSrcset(svgContent)}`} />
      <img src={`${svgToMiniDataURI(svgContent)}`} alt={alt} />
    </picture>
  )
}

export default SVGImage
