import React from 'react'

import ContactModal from '../../components/contact-modal'

const Slider = ({ title, description, ctaText, ctaSuccessMsg }) => {
  return (
    <div className="rn-slider-area">
      <div className="slide slide-style-1 slider-padded--height align-items-center">
        <div className="container">
          <div className="inner">
            <h1 className="title theme-gradient">{title}</h1>
            <p className="description theme-gradient mb--30">{description}</p>

            <ContactModal
              text={ctaText}
              origin="home-page-slider"
              successMsg={ctaSuccessMsg}
              className="rn-button-style--2"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Slider
