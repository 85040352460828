import React from 'react'
import Carousel from 'react-bootstrap/Carousel'

const Testimonial = ({ author, content, job, project }) => {
  return (
    <div>
      <div className="inner">
        <p>{content}</p>
      </div>
      <div className="author-info">
        <div>
          <span>{author}</span> – {job}
        </div>
        <em>{project}</em>
      </div>
    </div>
  )
}

// Dynamic CSS selectors: carousel, slide, carousel-indicators, carousel-inner
//                        carousel-control-prev-icon, carousel-control-next-icon,
//                        carousel-fade, carousel-item, carousel-item-left,
//                        carousel-item-right, carousel-item-next, carousel-item-prev,
//                        pointer-event, active
const Testimonials = ({ testimonials = [] }) => {
  return (
    <div className="rn-testimonial-area pt--120 pb--60 bg_color--1">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="rn-testimonial-content">
              <Carousel controls={false} interval={8000}>
                {testimonials.map(
                  ({ author, content, job, project }, index) => (
                    <Carousel.Item key={index}>
                      <Testimonial
                        author={author}
                        job={job}
                        content={content}
                        project={project}
                      />
                    </Carousel.Item>
                  )
                )}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonials
