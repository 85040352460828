import React, { useState } from 'react'
import NetlifyForm from '../../components/netlify-form'

import { trackEvent } from '../../services/matomo'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const ContactForm = ({ origin, successFn }) => {
  const [formState, setFormState] = useState({})
  const [formError, setFormError] = useState(null)

  const minChars = 50

  const errorMsg = 'Une erreur est survenue, veuillez réessayer.'

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  const isValid = () => {
    return (
      formState.email &&
      formState.firstname &&
      formState.lastname &&
      formState.message &&
      formState.message.length > minChars
    )
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const form = e.target

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        origin: origin,
        ...formState,
      }),
    })
      .then(() => {
        successFn()
        trackEvent('ContactForm', 'sent')
      })
      .catch((error) => setFormError(error))
  }

  return (
    <div className="form-wrapper contact-form--1">
      <NetlifyForm
        name="website-contact"
        submitFn={handleSubmit}
        changeFn={handleChange}
      >
        <input type="hidden" name="origin" value={origin} readOnly />

        <label>
          <input
            type="email"
            name="email"
            placeholder="Votre email *"
            required="required"
            onChange={handleChange}
          />
        </label>

        <label>
          <input
            type="text"
            name="firstname"
            placeholder="Votre prénom *"
            required="required"
            onChange={handleChange}
          />
        </label>

        <label>
          <input
            type="text"
            name="lastname"
            placeholder="Votre nom *"
            required="required"
            onChange={handleChange}
          />
        </label>

        <label>
          <input
            type="tel"
            name="phone"
            placeholder="Votre numéro de téléphone"
            onChange={handleChange}
          />
        </label>

        <label>
          <textarea
            id="item02"
            name="message"
            placeholder="Votre message *"
            required="required"
            onChange={handleChange}
          ></textarea>
          <small className="mt-n3 mb-3 form-text text-muted">
            {minChars} caractères minimum
          </small>
        </label>

        {formError && <div className="alert alert-danger">{errorMsg}</div>}

        <div className="text-right">
          <button
            className="rn-button-style--2 btn_solid"
            type="submit"
            value="submit"
            name="submit"
            id="mc-embedded-subscribe"
            disabled={!isValid()}
          >
            Envoyer
          </button>
        </div>
      </NetlifyForm>
    </div>
  )
}

export default ContactForm
