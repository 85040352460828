import React from 'react'

const NetlifyForm = ({ name, submitFn, changeFn, children }) => {
  return (
    <form
      name={name}
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={submitFn}
    >
      <input type="hidden" name="bot-field" onChange={changeFn} />
      <input type="hidden" name="form-name" value={name} onChange={changeFn} />

      {children}
    </form>
  )
}

export default NetlifyForm
