import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import Brands from '../../components/brands'
import Testimonials from '../../components/testimonials'

import Slider from './slider'
import About from './about'

import './main.scss'

const HomePage = ({ data }) => {
  const {
    frontmatter: {
      title,
      description,
      mainCTA,
      brands,
      promises,
      technologies,
      testimonials,
    },
  } = data.markdownRemark

  const mainPromise = promises[0]
  const secondPromise = promises[1]

  return (
    <Layout title={title} hideBreadcrumb classes="home-page">
      <Slider
        title={title}
        description={description}
        ctaText={mainCTA.text}
        ctaSuccessMsg={mainCTA.successMsg}
      />

      <About
        title={mainPromise.title}
        description={mainPromise.description}
        svgContent={mainPromise.illustration.fields.svgContent}
        cta={mainPromise.cta}
        services={mainPromise.caracteristics}
      />

      <Brands
        title={brands.title}
        brands={brands.items}
        rowColsClasses="row-cols-lg-4 row-cols-md-3 row-cols-2"
        colsClasses="p-3"
        bgColor="1"
      />

      <About
        title={secondPromise.title}
        description={secondPromise.description}
        svgContent={secondPromise.illustration.fields.svgContent}
        cta={secondPromise.cta}
        services={secondPromise.caracteristics}
        imagePosition="right"
      />

      <Testimonials testimonials={testimonials} />

      <div className="brands-footer">
        <Brands
          title={technologies.title}
          description={technologies.description}
          brands={technologies.items}
          rowColsClasses="row-cols-lg-5 row-cols-md-4 row-cols-3"
          colsClasses="p-3"
        />
      </div>
    </Layout>
  )
}

export default HomePage

HomePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export const pageQuery = graphql`
  query HomePageQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "home-page" } }) {
      frontmatter {
        title
        description
        mainCTA {
          text
          successMsg
        }

        promises {
          title
          description

          caracteristics {
            iconName
            title
            description

            cta {
              text
              url
            }
          }

          illustration {
            fields {
              svgContent
            }
          }
        }

        brands {
          title

          items {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 180) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }

        technologies {
          title
          description

          items {
            alt
            illustration {
              fields {
                svgContent
              }
            }
          }
        }

        testimonials {
          content
          author
          job
          project
        }
      }
    }
  }
`
