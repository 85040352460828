import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames/bind'

import SVGImage from '../../components/svg-image'

const AboutImage = ({ svgContent, imagePosition = 'left' }) => {
  const imageWrapperClasses = classNames('col-lg-5', 'col-md-12', {
    'order-lg-12': imagePosition == 'right',
  })

  return (
    <div className={imageWrapperClasses}>
      <div className="pr--30">
        <SVGImage svgContent={svgContent} />
      </div>
    </div>
  )
}

const AboutLink = ({ text, url, classes = 'btn-transparent rn-btn-dark' }) => (
  <Link to={url} className={classes}>
    {text}
  </Link>
)

const AboutServices = ({ title, description, cta, services }) => (
  <div className="col-lg-7 col-md-12">
    <div className="about-inner inner">
      <div className="section-title">
        <h2 className="title">{title}</h2>
        <p className="description">{description}</p>
      </div>

      <div className="row mt--30 mt_sm--10">
        {services.map(({ title, description, cta }, caracIndex) => (
          <div
            key={`carac_${caracIndex}`}
            className="col-md-6 col-sm-12 col-12 mb--30"
          >
            <div className="about-us-list">
              <h3 className="title">{title}</h3>
              <p>{description}</p>

              {cta && <AboutLink text={cta.text} url={cta.url} />}
            </div>
          </div>
        ))}
      </div>

      <div className="mt--15">
        {cta && <AboutLink text={cta.text} url={cta.url} />}
      </div>
    </div>
  </div>
)

const About = ({ title, description, cta, services, ...imageOptions }) => {
  return (
    <div className="about-area about-position-top ptb--120">
      <div className="about-wrapper">
        <div className="container">
          <div className="row align-items-center">
            <AboutImage {...imageOptions} />
            <AboutServices
              title={title}
              description={description}
              services={services}
              cta={cta}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
