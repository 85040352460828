import React from 'react'
import Img from 'gatsby-image'

import SVGImage from '../../components/svg-image'

import './main.scss'

const Brands = ({
  title,
  description,
  brands,
  rowColsClasses = 'row-cols-4',
  colsClasses = 'w-75',
  bgColor,
}) => {
  const BrandImg = ({ image, alt }) => (
    <Img
      fluid={image.childImageSharp.fluid}
      className="brands--img"
      alt={alt}
      title={alt}
      height="150"
      width="110"
      imgStyle={{ bottom: '0', right: '0', margin: 'auto' }}
    />
  )

  const BrandIllustation = ({ svgContent, alt }) => (
    <SVGImage
      svgContent={svgContent}
      alt={alt}
      title={alt}
      className="brands--illustration"
    />
  )

  return (
    <div className={'rn-brand-area ' + (bgColor ? 'bg_color--' + bgColor : '')}>
      <div className="container">
        <div className="section-title text-center pt--60">
          <h2 className="title">{title}</h2>
          {description && <p>{description}</p>}
        </div>
        <div
          className={`row ${rowColsClasses} align-items-center justify-content-center ptb--60`}
        >
          {brands.map(({ image, illustration, alt }, index) => {
            let content

            if (image) {
              content = <BrandImg image={image} alt={alt} />
            }

            if (illustration) {
              content = (
                <BrandIllustation
                  svgContent={illustration.fields.svgContent}
                  alt={alt}
                />
              )
            }

            return (
              <div
                key={`brand_${index}`}
                className="col justify-content-center d-flex"
              >
                <div className={colsClasses}>{content}</div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Brands
