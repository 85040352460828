import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { StaticQuery, graphql } from 'gatsby'

import ContactForm from '../contact-form'
import SVGImage from '../svg-image'

import { trackEvent } from '../../services/matomo'

const ContactModalSuccess = ({ successMsg }) => {
  const query = graphql`
    query ContactSuccessQuery {
      file(relativePath: { eq: "contact-modal/sent.svg" }) {
        fields {
          svgContent
        }
      }
    }
  `

  return (
    <div className="ptb--15">
      <StaticQuery
        query={query}
        render={(data) => (
          <SVGImage
            svgContent={data.file.fields.svgContent}
            height="280"
            width="465"
            alt="Message sent"
          />
        )}
      />
      <div className="mt--30">{successMsg}</div>
    </div>
  )
}

const ContactModal = ({ text, origin, successMsg = 'Merci !', ...rest }) => {
  const [show, setShow] = useState(false)
  const [showForm, setShowForm] = useState(true)

  const sendContactModalEvent = (event) => {
    trackEvent('ContactModal', event, origin)
  }

  const onClose = () => {
    setShow(false)
    sendContactModalEvent('close')
  }
  const onClick = () => {
    setShow(true)
    sendContactModalEvent('open')
  }

  const onSuccess = () => {
    setShowForm(false)
    sendContactModalEvent('sent')
  }

  // Dynamic CSS selectors: modal-dialog, modal-dialog-centered, fade, modal
  //                        modal-backdrop, show, modal-content, modal-header
  //                        modal-body, form-wrapper, contact-form--1, close
  //                        sr-only, modal-title
  return (
    <>
      <button onClick={onClick} {...rest}>
        {text}
      </button>

      <Modal show={show} onHide={onClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{text}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {showForm ? (
            <ContactForm origin={origin} successFn={onSuccess} />
          ) : (
            <ContactModalSuccess successMsg={successMsg} />
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ContactModal
